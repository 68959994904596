/*variables*/

var isActiveMobileMenu = false;
var windowsize;

$(document).ready(function () {
    //Add dots to "zastosuj" button on flat-list
    $("#filter-element").on("change", function () {
        document.getElementById("show-dots").removeAttribute("style");
    })

    navbarCalculate();

    /*navbar - button*/
    $(".btn-navbar-collapse").click(function () {
        clickBtnNavbar();
    });

    //NAVBAR
    window.onscroll = function () {
        navbarCalculate();
    }

    $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
    });

    /* flats list filtration */
        $('#show-filter-results').click(function () {
            let obj = $('#form_filtry');
            if (obj) {
                obj.submit();
            } else {

            }
        });

        var surfaceRestore, qtyRoomRestore, priceM2Restore, priceRestore;

        $( document ).ready(function() {
            surfaceRestore = $('#surface-Slider-id').val().split(',');
            qtyRoomRestore = $('#qtyRoom-Slider-id').val().split(',');
            priceM2Restore = $('#price-m2-Slider-id').val().split(',');
            priceRestore = $('#price-Slider-id').val().split(',');
        });

        $('#surface-Slider-id').change(function () {
            $('#form-listing-surface').val($(this).val());
        });
        $('#qtyRoom-Slider-id').change(function () {
            $('#form-listing-qtyRoom').val($(this).val());
        });
        $('#price-m2-Slider-id').change(function () {
            $('#form-listing-price-m2').val($(this).val());
        });
        $('#price-Slider-id').change(function () {
            $('#form-listing-price').val($(this).val());
        });

        function ajaxFilters(filter){
            $.ajax({
                url: 'filtry',
                type: "GET",
                dataType: 'json',
                data: {
                    '_method': 'PUT',
                    'surface': $('#surface-Slider-id').val(),
                    'qtyRoom': $('#qtyRoom-Slider-id').val(),
                    'price_m2': $('#price-m2-Slider-id').val(),
                    'price':  $('#price-Slider-id').val(),
                    'filter': filter
                },
                success: function (data, status) {
                   ///if results empty, then restore last options
                   if(data.cena_max == null){
                         $('#surface-Slider-id').slider('setValue',[parseInt(surfaceRestore[0]),parseInt(surfaceRestore[1])]);
                         $('#qtyRoom-Slider-id').slider('setValue',[parseInt(qtyRoomRestore[0]),parseInt(qtyRoomRestore[1])]);
                         $('#price-m2-Slider-id').slider('setValue',[parseInt(priceM2Restore[0]),parseInt(priceM2Restore[1])]);
                         $('#price-Slider-id').slider('setValue',[parseInt(priceRestore[0]),parseInt(priceRestore[1])]);
                   }
                   else{
                       if(data.filter != 0){
                             $('#surface-Slider-id').slider('setValue',[parseInt(data.metraz.metraz_min),parseInt(data.metraz.metraz_max)]);
                             $('#form-listing-surface').val($('#surface-Slider-id').val());
                       }
                       if(data.filter != 1){
                             $('#qtyRoom-Slider-id').slider('setValue',[parseInt(data.pokoje.pokoje_min),parseInt(data.pokoje.pokoje_max)]);
                             $('#form-listing-qtyRoom').val($('#qtyRoom-Slider-id').val());
                       }
                       if(data.filter != 2){
                             $('#price-m2-Slider-id').slider('setValue',[parseInt(data.cena_m2.cena_m2_min),parseInt(data.cena_m2.cena_m2_max)]);
                             $('#form-listing-price-m2').val($('#price-m2-Slider-id').val());
                       }
                       if(data.filter != 3){
                             $('#price-Slider-id').slider('setValue',[parseInt(data.cena_min),parseInt(data.cena_max)]);
                             $('#form-listing-price').val($('#price-Slider-id').val());
                       }
                   }
                     surfaceRestore = $('#surface-Slider-id').val().split(',');
                     qtyRoomRestore = $('#qtyRoom-Slider-id').val().split(',');
                     priceM2Restore = $('#price-m2-Slider-id').val().split(',');
                     priceRestore = $('#price-Slider-id').val().split(',');
                }
            });
        }

        $('#surface-Slider-id').slider().on('slideStop', function(ev){
            ajaxFilters(0);
        });
        $('#qtyRoom-Slider-id').slider().on('slideStop', function(ev){
            ajaxFilters(1);
        });
        $('#price-m2-Slider-id').slider().on('slideStop', function(ev){
            ajaxFilters(2);
        });
        $('#price-Slider-id').slider().on('slideStop', function(ev){
            ajaxFilters(3);
        });
    /* END flats list filtration */

});


/*slider filters price */
$(function () {
    var surfaceSlider = $("#surface-Slider-id").slider();
    var qtyRoomSlider = $("#qtyRoom-Slider-id").slider();
    var pricem2Slider = $("#price-m2-Slider-id").slider();
    var priceSlider = $("#price-Slider-id").slider();


    windowsize = $(window).width();
    changedFilterPrice();

    $(window).resize(function () {
        windowsize = $(window).width();
        changedFilterPrice();
    });

    $("#filter-mobile-show").click(function () {
        $("#filter-element").show();
        $("body").css({'overflow': 'hidden'});
    });


    $("#close-filter, #show-filter-results").click(function () {
        if (windowsize < 992) {
            $("#filter-element").hide();
            $("body").css({'overflow': 'visible'});
        }
    });
});

/*show bigger img*/
$(function () {
    "use strict";

    $(".popup img").click(function () {
        var $src = $(this).attr("src");
        $(".showGallery").css({'display': 'block'});
        $(".img-show img").attr("src", $src);
    });

    $("span, .overlay").click(function () {
        $(".showGallery").css({'display': 'none'});
    });

});

/*load more images (gallery-all.blade.php)*/
$(function () {
    $(".load-more-item").slice(0, 4).show();
    checkBtnLoadMore();

    $("#loadMore").on('click', function (e) {
        e.preventDefault();
        $(".load-more-item:hidden").slice(0, 4).slideDown();
        $('html,body').animate({
            scrollTop: $(this).offset().top
        }, 1500);
        checkBtnLoadMore();
    });
});


/*
* functions
*/

function changedFilterPrice() {
    if (windowsize > 992) {
        $("#filter-element").appendTo("#filter-desktop");
    } else {
        if ($("#loadingFilters").length) {
            $("#loadingFilters").remove();
        }

        $("#filter-element").appendTo("#filter-mobile");
    }
}

function navbarCalculate() {
    if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
        navbarFluid();
    } else {
        navbarStatic();
    }
}

function navbarFluid() {
    $('.navbar').addClass('navbar-fluid');
    $('.navbar').removeClass('navbar-static');
}

function navbarStatic() {
    $('.navbar').removeClass('navbar-fluid');
    $('.navbar').addClass('navbar-static');
}

function clickBtnNavbar() {
    if ($('.btn-navbar-collapse').hasClass('collapsed')) {
        $('body').css({'overflow-y': 'hidden'});
    } else {
        $('body').css({'overflow-y': 'visible'});
    }
}

function checkBtnLoadMore() {
    if ($(".load-more-item:hidden").length > 0) {
        $("#loadMore").show();
    } else if ($(".load-more-item:hidden").length == 0) {
        $("#loadMore").hide();
    }
}

function imgError(image) {
    image.onerror = "";
    image.src = "front/images/no_img.jpg";
    return true;
}

window.onload = function () {
    var iframeElem = document.getElementsByTagName('iframe');
    for (var i = 0; i < iframeElem.length; i++) {
        if (iframeElem[i].getAttribute('data-src')) {
            iframeElem[i].setAttribute('src', iframeElem[i].getAttribute('data-src'));
        }
    }
};


/*
* end functions
*/